<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp">
            <v-sheet style="padding-top:140px; padding-bottom:200px;" width="1000" class="mx-auto">
                <p class="text-center text-h4 font-weight-bold mb-10">약관 동의</p>
                <v-divider class="mb-8"></v-divider>
                <div>
                    <v-checkbox
                        class="ml-2"
                        v-model="all"
                        color="#a87675"
                        label="이용약관 전체 동의"
                        required
                        @change="check_all()"
                    ></v-checkbox>
                </div>
                <div class="rule_box" style="padding: 20px">
                  <TermsOfUse/>
                </div>
                <div style="min-width:600px; margin-top:10px; margin-bottom:60px;">
                    <v-checkbox
                        class="ml-2"
                        color="#a87675"
                        v-model="rules"
                        :error-messages="rules_Errors"
                        label="위셀페이 이용약관 동의"
                        required
                        @change="$v.rules.$touch()"
                        @blur="$v.rules.$touch()"
                    ></v-checkbox>
                </div>

                <div class="rule_box" style="padding: 20px">
                  <TermsOfElectronicFinancialTrans/>
                </div>
                <div style="min-width:600px; margin-top:10px; margin-bottom:60px;">
                    <v-checkbox
                        class="ml-2"
                        color="#a87675"
                        v-model="cash"
                        :error-messages="cash_Errors"
                        label="전자금융거래 이용약관 동의"
                        required
                        @change="$v.cash.$touch()"
                        @blur="$v.cash.$touch()"
                    ></v-checkbox>
                </div>

                <div class="rule_box" style="padding: 20px">
                  <TermsOfPrivacyPolicy/>
                </div>
                <div style="min-width:600px; margin-top:10px; margin-bottom:40px;">
                    <v-checkbox
                        class="ml-2"
                        color="#a87675"
                        v-model="privacy"
                        :error-messages="privacy_Errors"
                        label="개인정보수집/이용안내 동의"
                        required
                        @change="$v.privacy.$touch()"
                        @blur="$v.privacy.$touch()"
                    ></v-checkbox>
                </div>
                <v-sheet class="d-flex justify-center">
                    <v-btn @click="submit()" width="400" class="py-6" x-large tile depressed dark color="#a87675">가입 신청</v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp">
            <v-sheet class="my-16 mx-auto">
                <p class="text-center text-h4 font-weight-bold mb-6">약관 동의</p>
                <v-divider class="mb-6 mx-6"></v-divider>
                <div class="mx-6">
                    <v-checkbox
                        color="#a87675"
                        v-model="all"
                        label="이용약관 전체 동의"
                        required
                        @change="check_all()"
                    ></v-checkbox>
                </div>
                <div class="rule_mobile_box" style="padding: 20px">
                  <TermsOfUse/>
                </div>
                <div class="mx-6 mb-7">
                    <v-checkbox
                        color="#a87675"
                        v-model="rules"
                        :error-messages="rules_Errors"
                        label="위셀페이 이용약관 동의"
                        required
                        @change="$v.rules.$touch()"
                        @blur="$v.rules.$touch()"
                    ></v-checkbox>
                </div>

                <div class="rule_mobile_box" style="padding: 20px">
                  <TermsOfElectronicFinancialTrans/>
                </div>
                <div class="mx-6 mb-7">
                    <v-checkbox
                        color="#a87675"
                        v-model="cash"
                        :error-messages="cash_Errors"
                        label="전자금융거래 이용약관 동의"
                        required
                        @change="$v.cash.$touch()"
                        @blur="$v.cash.$touch()"
                    ></v-checkbox>
                </div>

                <div class="rule_mobile_box" style="padding: 20px">
                    <TermsOfPrivacyPolicy/>
                </div>
                <div class="mx-6 mb-7">
                    <v-checkbox
                        color="#a87675"
                        v-model="privacy"
                        :error-messages="privacy_Errors"
                        label="개인정보수집/이용안내 동의"
                        required
                        @change="$v.privacy.$touch()"
                        @blur="$v.privacy.$touch()"
                    ></v-checkbox>
                </div>
                <v-sheet class="d-flex justify-center mx-6">
                    <v-btn @click="submit()" block class="py-6" x-large tile depressed dark color="#a87675">가입 신청</v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
import { validationMixin } from 'vuelidate'
import TermsOfUse from "@/views/policy/text/TermsOfUse";
import TermsOfPrivacyPolicy from "@/views/policy/text/TermsOfPrivacyPolicy";
import TermsOfElectronicFinancialTrans from "@/views/policy/text/TermsOfElectronicFinancialTrans";

export default {
    mixins: [validationMixin],
    components:{
      TermsOfUse,
      TermsOfPrivacyPolicy,
      TermsOfElectronicFinancialTrans,
    },

    validations: {
        rules: {
            checked (val) {
                return val
            },
        },
        cash: {
            checked (val) {
                return val
            },
        },
        privacy: {
            checked (val) {
                return val
            },
        }
    },

    data: () => ({
        all:false,
        rules:false,
        cash:false,
        privacy:false,
        submitStatus: null
    }),

    computed: {
        rules_Errors () {
            const errors = []
            if (!this.$v.rules.$dirty) return errors
            !this.$v.rules.checked && errors.push('위셀페이 이용약관에 동의하셔야 가입하실 수 있습니다.')
            return errors
        },
        cash_Errors () {
            const errors = []
            if (!this.$v.cash.$dirty) return errors
            !this.$v.cash.checked && errors.push('전자금융거래 이용약관에 동의하셔야 가입하실 수 있습니다.')
            return errors
        },
        privacy_Errors () {
            const errors = []
            if (!this.$v.privacy.$dirty) return errors
            !this.$v.privacy.checked && errors.push('개인정보수집/이용안내에 동의하셔야 가입하실 수 있습니다.')
            return errors
        }
    },

    methods: {
        check_all(){
            if(this.all){
                this.rules=true;
                this.cash=true;
                this.privacy=true;
            }else{
                this.rules=false;
                this.cash=false;
                this.privacy=false;
            }
        },
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'
                setTimeout(() => {
                this.submitStatus = 'OK'
                }, 500)
                this.$router.push('/join/form')
            }
        }
    }
}
</script>
<style>
.rule_box{
    min-width:600px;
    height: 300px;
    overflow: auto;
    border: 1px solid #e9e9e9;
    text-align:left;
    padding:5px;
    background:#f9f9f9;
}

.rule_box h2{
    color:#a87675;
    margin-bottom:10px;
}


.rule_box h3{
    color:#333;
}

.rule_mobile_box{
    height: 200px;
    overflow: auto;
    border: 1px solid #e9e9e9;
    text-align:left;
    padding:5px;
    margin: 0 20px;
    background:#f9f9f9;
}

.rule_mobile_box h2{
    color:#a87675;
    margin-bottom:10px;
}


.rule_mobile_box h3{
    color:#333;
}
</style>