<template>
  <div>
    <v-sheet>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="320"
        >
          <v-card>
            <v-card-title class="text-h5">
              휴대폰 인증
            </v-card-title>
            <div>
              <div>
                <v-sheet rounded class="pa-2 d-flex align-center mt-4" style="background:#ececec !important;">
                  <v-sheet min-width="80" color="transparent" class="text-center">
                    <font style="font-size:15px;">인증번호</font>
                  </v-sheet>
                  <v-text-field
                      class="pt-5 px-2"
                      placeholder="인증번호 입력"
                      dense
                      color="brown"
                      style="background:#ececec !important;"
                      required
                      v-model="authNumber"
                      :error-messages="authNumber_Errors"
                  ></v-text-field>
                </v-sheet>
                <div class="mt-4 pl-3">
                  <span>인증번호 만료시간 : </span>
                  <CountDown
                      ref="countDown"
                      style="display: inline-block"
                      v-bind:count="180"
                      v-on:timeoutEvent="timeoutEventHandler"
                  />
                  <span>초</span>
                </div>
              </div>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="auth"
              >
                인증
              </v-btn>
              <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
              >
                취소
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required,  minLength, maxLength } from 'vuelidate/lib/validators'
import CountDown from '../../common/CountDown'

const {isEmpty} = require("@/lib/CommUtil");
const {ValidateApi} = require("@/api/ValidateApi");

export default {
  name: "SellerCellphone",
  mixins: [validationMixin],
  components:{
    CountDown
  },
  validations: {
    authNumber: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
  computed:{
    authNumber_Errors () {
      const errors = []
      if (!this.$v.authNumber.$dirty) return errors
      !this.$v.authNumber.required && errors.push('인증번호를 입력해주세요')
      !this.$v.authNumber.minLength && errors.push('6자이여야합니다')
      !this.$v.authNumber.maxLength && errors.push('6자이여야합니다')
      return errors
    },
  },
  data () {
    return {
      dialog: false,
      authNumber:'',
      resultCallback: null,
      sellerCellPhone:null,
      authSessionKey:null,
      phoneNumber:null,
    }
  },
  methods:{
    open(number,callback){
      this.dialog=true
      this.resultCallback= callback
      this.sellerCellPhone=number
      ValidateApi.issueSmsAuth(number).then( res =>{
        console.log('issueSmsAuth',res)
        this.authSessionKey = res.authSessionKey
        this.phoneNumber=res.phoneNumber
      }).catch(()=>{
        alert('요청 실패입니다. 다시 시도해 주세요')
        this.dialog=false
      })
    },
    async auth(){
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        alert('인증번호를 입력해 주세요')
        return
      }
      if(isEmpty(this.authSessionKey)){
        alert('인증번호가 발송되지 않았습니다\n')
        this.dialog=false
        return
      }
      const request = {
        authNumber: this.authNumber,
        phoneNumber: this.phoneNumber,
        authSessionKey: this.authSessionKey
      }
      ValidateApi.validateSmsAuthNumber(request).then( res =>{
        console.log('validateSmsAuthNumber',res)
        this.resultCallback(res)
        this.$refs.countDown.clear()
      }).catch(()=>{
        alert('요청 실패입니다. 다시 시도해 주세요')
        this.resultCallback(false)
        this.dialog=false
        this.$refs.countDown.clear()
      })
      this.reset()
    },
    timeoutEventHandler(){
      this.dialog=false
      this.$refs.countDown.clear()
    },
    reset(){
      this.authNumber=''
    },
    close(){
      this.dialog=false
    }
  }

}
</script>

<style scoped>

</style>