<template>
  <div>
    {{ countDown }}
  </div>
</template>

<script>
export default {
  name: "CountDown",
  props:{
    count:Number
  },
  data() {
    return {
      countDown : 0,
      timer: null,
    }
  },
  watch: {
    countDown: {
      handler(value) {

        if (value > 0) {
          this.timer = setTimeout(() => {
            this.countDown--;
            if(this.countDown === 0){
              this.$emit('timeoutEvent')
            }
          }, 1000);
        }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  },
  created() {
    this.countDown = this.count
  },
  methods: {
    clear(){
      clearTimeout(this.timer)
      this.countDown = this.count
    }
  },

}
</script>

<style scoped>

</style>